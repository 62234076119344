import React from "react";
import "../../styles/gst.css";
import Testimonial from "../../components/Testimonial";
import FAQAccord from "../../components/GFlush";
import Layout from "../../components/Layout";
import { Seo } from "../../components/seo";
import Counter from "../../components/Counter";
import Guidance from "../../components/GSTTemp/Guidance";
// import Hero from "../../components/Hero";
import AboutWithForm from "../../components/GSTTemp/AboutWithForm";
import ImgContent from "../../components/GSTTemp/ImgContent";
import ScrollNav from "../../components/GSTTemp/ScrollNav";
import ContentForm from "../../components/GSTTemp/ContentForm";
import Cta from "../../components/Cta";
import ContentImg from "../../components/GSTTemp/ContentImg";
import SliderContent from "../../components/GSTTemp/SliderContent";
import Package from "../../components/GSTTemp/Package";

export default function TrademarkRenewal() {
  /* Slider */
  // const renewalSlider = [
  //   {
  //     header_color: false,
  //     content_color: false,
  //     marginTop: 30,
  //     background: `linear-gradient(139.4deg, rgb(252, 237, 216) 28.54%, rgb(245, 197, 198) 104.43%)`,
  //     header: `Online Trademark Renewal`,
  //     content: `Best Online Trademark Renewal Service in Hosur`,
  //     image: "/imgs/registration/ipr/td9.png",
  //     alt_tag: "Best Online Trademark Renewal Service in Hosur",
  //   },
  // ];

  /* Package Data */
  const PackageCompData = {
    header: `Trademark Renewal`,
    buyBtnLink: `#pricing-buy`,
    price: `6999`,
    content: [
      {
        points: `PAN & TAN Registration `,
        icon: "/imgs/icons/bregistration/pancard.png",
        alt_tag: "PAN & TAN Registration",
        background: "",
        plus: "+",
      },
      {
        points: `PF + ESIC + Professional Tax`,
        icon: "/imgs/icons/bregistration/register.png",
        alt_tag: "Professional Tax Service",
        background: "bg-f78acb",
        plus: "+",
        plus_dnone: "d-none d-md-block",
      },
      {
        points: `Free Trademark
        Search Report`,
        icon: "/imgs/icons/bregistration/audit.png",
        alt_tag: "Trademark Search Report",
        background: "bg-eb6b3d",
        plus: "+",
      },
      {
        points: `10% discount on 4+ 
          applications`,
        icon: "/imgs/icons/it/80u.png",
        alt_tag: "Discount",
        background: "bg-c679e3",
      },
    ],
  };

  /* About Data With Form*/
  const renewalAboutData = {
    header: `Online Trademark Renewal`,
    sub_title: `File Online Trademark Renewal with the TODAYFILINGS Experts.`,
    content_paragraph: [
      `Trademark is a registration that a company obtains in order to set its products or services apart from 
      those of its rivals or other companies operating in the same market or sector. After 12 months from the 
      date of expiration, if the trademark registration holder does not renew the registration, the trademark 
      will be deleted from the Trademarks Journal or the Register of Trademarks.`,

      // `Annoying components Government logos or insignia are there, just like in
      // previous trademark registrations in India that have already been made.If any
      // of the mention details is contained, then the registration application for the
      // trademark will be rejected.`
    ],
  };
  /* Scroll Nav Data */
  const renewalScrollId = [
    {
      id: `#Procedure`,
      heading: `Procedure`,
    },
    {
      id: `#documents`,
      heading: `Documents Required`,
    },
    {
      id: `#role`,
      heading: `Our Role`,
    },
    {
      id: `#trademark`,
      heading: `Trademark Restoration `,
    },
  ];

  /* Img Content Component Data */
  const renewalIcData = {
    id: 'Procedure',
    mt_div: '',
    mt_img: '',
    header: 'Trademark Renewal Procedure',
    image: '/imgs/registration/ipr/td2.png',
    alt_tag: 'trademark renewal Procedure',
    Paragraph: `The application status should always be checked by the applicant to make sure that the 
    registrar has processed and moved on with the same. The renewal made shall be valid for the next ten years,The recognized trademark would be published in the trademark registry's official journal, which also 
    governs the trademark's approval or rejection.`,
    points: [
      {
        head: 'Trademark Renewal prerequisites',
        content: `Comparable to other trademark applications already filed in India and Containing Harmful Elements.`,
        icon: true,
      },
      {
        content: `Is using government symbols and logos interchangeable`,
        icon: true,
      },
      {
        content: `The registered trademark will only be valid for ten years and must be renewed before that time.`,
        icon: true,
      },
      {
        content: `Use Form TM-R to submit your trademark renewal application.`,
        icon: true,
      },
      {
        content: `The applicant, the trademark holder, or a designated authorized representative
         organization designated by the applicant shall submit the renewal application.`,
        icon: true,
      },
    ],
  };
  /* Content Form Component Data */
  const renewalCfData = {
    id: 'documents',
    mt_div: 'mt-5',
    mt_img: 'mt-5',
    header: 'Documents Required For Trademark Renewal',
    content: ``,
    body: [
      {
        points: `Copy of the trademark registration certificate.`,
        icon: true,
      },
      {
        points: `Power of attorney demonstrating the applicant's representation.`,
        icon: true,
      },
      {
        points: `The applicant's residence documentation (e.g., Land Documents, Rental Documents)`,
        icon: true,
      },
      {
        points: `Proof of the petitioner's identification (e.g., Aadhar, Pan, or Voter ID) `,
        icon: true,
      },
      {
        head: 'Trademark Renewal – Checklist',
        points: ``,
        icon: false,
      },
      {
        points: `The entity or business owner, as the case may be, shall own title to the trademark.`,
        icon: true,
      },
      {
        points: `It must be getting close to the expiration date.`,
        icon: true,
      },
      {
        points: `There shouldn't be any conflicts, and if 
        there are, the applicant should resolve them by getting an expert opinion.`,
        icon: true,
      },
      {
        points: `After meeting the prerequisites, a renewal application for a trademark in India should be prepared.`,
        icon: true,
      },
    ],
  };
  /*  Content Img Component Data */
  const renewalCiData = {
    id: 'trademark',
    background: 'main-content',
    mt_div: 'mt-3',
    header: 'Restoration Of A Trademark',
    paragraph: ``,
    points: [
      {
        content: `We frequently witness situations developing when business owners or management forget to renew a trademark before 
        its expiration date. According to section 25(4) of the Trademark Act of 19999, the trademark owner may restore the same in such circumstances.`,
        icon: true,
      },
      {
        content: `However, it must be done within a maximum of 12 months of the date of expiration, and fines and fees must also be paid.`,
        icon: true,
      },
      {
        content: `Restoration of a trademark is an action or process of bringing back the mark to the register of trademarks, which has been 
        removed from it. There are several instances when a mark is removed from the register. These reasons go under a rigorous scrutiny 
        before being removed and getting a second chance for the proprietor is difficult in majority of the situations.`,
        icon: true,
      },
    ],
    image: '/imgs/registration/msme/msme-img.png',
    alt_tag: 'Required Documents for Trademark Renewal',
  };

  /* ImgSlider Data */
  const MsMeImages = [
    '/imgs/registration/ipr/td1.png',
    '/imgs/registration/ipr/td9.png',
    '/imgs/registration/ipr/td2.png',
  ];

  /*  Slider Content Component Data */
  const renewalSCData = {
    id: 'role',
    background: [],
    mt_div: 'mt-3',
    header: 'Our Role In GST Filing Services',
    content: [
      {
        points: `TODAYFILINGS is the place to go for your trademark renewal because they have some of the top market specialists 
        and the most affordable prices. Get in touch with us to complete it as quickly as possible.`,
        icon: false,
      },
      {
        points: ``,
        icon: false,
      },
    ],
    images: MsMeImages,
  };

  var FAQ_data = [
    {
      header: 'Why should my trademark be renewed?',
      body: [
        {
          content: `This enables the business to acquire a valuable asset in the form of
          a logo or emblem that is linked to their brand and helps customers
          recognize their goods and services from those of competitors. And
          for them to have access to this, the trademark renewal should have
          been in accordance with every ten years of existence.`,
          icon: false,
        },
      ],
    },

    {
      header: 'When can I apply for Trademark Renewal?',
      body: [
        {
          content: `The organization or trademark proprietor may apply for renewal at
          least six months before the expiry date. If the trademark
          registration expires on October 31, 2022, the company can file for
          renewal on May 1, 2022.`,
          icon: false,
        },
      ],
    },
    {
      header: 'Is it possible to reclaim the trademark?',
      body: [
        {
          content: ` Yes, it can be reinstated within a maximum of 12 months of the
          expiry date. If the trademark registration expires on August 28,
          2022, the restoration opportunity will be active until August 28,
          2023.`,
          icon: false,
        },
      ],
    },
    {
      header: 'What documents are required for Trademark renewal?',
      body: [
        {
          content: `The application for trademark renewal needs to have these documents 
          attached a copy of the registration certificate, power of attorney, ID and address proof of the applicant, 
          and a copy of the TM 1 form.`,
          icon: false,
        },
      ],
    },
    {
      header: 'What are the benefits of trademark renewal?',
      body: [
        {
          content: `The trademark registration is an asset for the company under this a word or visual symbol is used
           by a business to help them distinguish the goods and services from that other similar goods and services. Thus, 
           renewing a trademark is always beneficial.`,
          icon: false,
        },
      ],
    },
    {
      header: 'When to apply for Trademark renewal in India?',
      body: [
        {
          content: `A trademark renewal application should be made six months before the expiry of the registration. 
          An application is to be made with the payment of fees.`,
          icon: false,
        },
      ],
    },
    {
      header: 'Can a trademark be restored?',
      body: [
        {
          content: `A trademark can be restored after the expiry within 6 months to keep using it. An application is to be made for the same.`,
          icon: false,
        },
      ],
    },
    {
      header:
        'What is the difference between trademark registration and trademark renewal?',
      body: [
        {
          content: `The trademark registration helps an individual obtain exclusive rights for the use of words or symbols. Whereas the 
          Trade renewal helps in continuing the ownership and use of the trademark.`,
          icon: false,
        },
      ],
    },
    {
      header: ' For how many years is a trademark registration valid?',
      body: [
        {
          content: `Trademark registration is valid for 10 years, the process of renewal begins after the period of expiry.`,
          icon: false,
        },
      ],
    },
    {
      header: 'What happens case if there is a failure to renew the trademark?',
      body: [
        {
          content: `In case if the trademark registration is not renewed the trademark would be removed from
           the register and any other person claim and get it registered to their name.`,
          icon: false,
        },
      ],
    },
  ];
  return (
    <div>
      <Seo
        title='Trademark Renewal'
        keywords='Keywords'
        description='Description'
        name='TODAYFILINGS'
        type='Website'
        author='TODAYFILINGS'
      />
      <Layout>
        <main id='main'>
          {/* <Hero sliders={renewalSlider} /> */}
          <Package packageData={PackageCompData} />

          <AboutWithForm about_content={renewalAboutData} />
          <Counter />
          <ScrollNav scroll_data={renewalScrollId} />
          <ImgContent item={renewalIcData} />
          <ContentForm CFSection_data={renewalCfData} />
          <SliderContent ScSection_data={renewalSCData} />
          <ContentImg CISection_data={renewalCiData} />

          <Guidance />

          <Cta />
          <Testimonial />
          <FAQAccord items={FAQ_data} />
        </main>
      </Layout>
    </div>
  );
}
